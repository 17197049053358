<template>
  <div v-if="isPermission">
    <CCard class="shadow-sm m-2">
      <CCardBody>
        <CRow>
          <CCol sm="1" col="2">
            <img src="/img/icon/store.png" style="width: 32px; height:32px;"/>
          </CCol>
          <CCol sm="9" col="7">
            <select class="custom-select text-dark" v-model="shop" disabled>
              <option v-for="shop in shops" :key="shop.objectId" :value="shop">
                {{ shop.shopName }} - {{ shop.branchName }}
              </option>
            </select>

            <div v-if="validateShop" class="text-danger">
              {{ validateShop }}
            </div>
          </CCol>
          <div class="col-md-2 col-sm-2 col-3 text-right">
            <CButton block color="light" @click="goback()">
              <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
            </CButton>
          </div>
        </CRow>
      </CCardBody>
    </CCard>
    <form class="mt-4">
      <CCol md="12" lg="12">
        <CCard class="shadow-sm">
          <CCardBody>
            <CAlert color="success" v-if="isSuccess === true">
              {{ $t('createProductSuccess') }}
            </CAlert>
            <div class="row">
              <div class="col-md-10 col-sm-9 col-9">
                <h2 class="font-weight-normal text-dark">
                  {{ $t('productPLU') }}
                  <span v-if="items.length > 0">({{ countPLU }}) </span>
                </h2>
                <h2 class="font-weight-normal text-dark">
                  <span class="h3">
                   {{ $t('productName') }} :
                    {{ SKUName }}
                  </span>
                </h2>
              </div>
              <div class="col-md-2 col-sm-3 col-3 text-right text-dark" v-show="!isEcommercePage" v-if="isEditData">
                <CButton block color="success" @click="openAddPluModal()">
                  {{ $t('addProductPLU') }}
                </CButton>
              </div>
            </div>
            <hr />
            <CRow v-if="items.length > 0">
              <CCol sm="12" lg="12">
                <div style="overflow-x: auto">
                  <table class="table table-hover" style="white-space:nowrap;">
                    <thead class="table-borderless">
                      <tr>
                        <th class="text-dark font-weight-normal" style="width:7%">{{ $t('img') }}</th>
                        <th class="text-dark font-weight-normal">{{ $t('pluCode') }}</th>
                        <th class="text-dark font-weight-normal" style="width:40%">{{ $t('productName') }}</th>
                        <th class="text-dark font-weight-normal">{{ $t('unit') }}</th>
                        <th class="text-dark font-weight-normal text-right">{{ $t('SKURatio') }}</th>
                        <th class="text-dark font-weight-normal text-right">{{ $t('price') }}</th>
                        <th v-if="isEditData" style="width:3%"></th>
                      </tr>
                    </thead>
                    <tbody v-if="loadingButton == true">
                      <tr
                        v-for="(item, index) in items"
                        :key="index"
                        hover
                        border
                        striped
                      >
                        <td>
                          <img
                            v-if="item.remoteImagePath"
                            :src="item.remoteImagePath"
                            style="border-radius: 5px;aspect-ratio: 4 / 3;"
                            class="img-fluid"
                            @error="noImgUrl"
                          />
                          <div
                            v-else
                            style="border-radius: 5px;aspect-ratio: 4 / 3;"
                            :style="{
                              backgroundColor: item.colori,
                            }"
                          ></div>
                        </td>
                        <td class="text-dark font-weight-normal">{{ item.PLUCode }}</td>
                        <td class="text-dark font-weight-normal">{{ item.name }}</td>
                        <td class="text-dark font-weight-normal">
                          {{ item.unit.name != undefined ? item.unit.name : 'N/A'}}
                        </td>
                        <td class="text-dark font-weight-normal text-right">{{ item.SKURatio }}</td>
                        <td class="text-dark font-weight-normal text-right">{{ item.price }}</td>
                        <td v-if="isEditData">
                          <CButton @click="openEditPluModal(item, index)">
                            <img src="/img/file-edit.png" style="width: 14px; height: 14px; margin-right: 8px; cursor: pointer;"/>  
                          </CButton>
                          <CButton @click="deletePLU(item, index)">
                            <img src="/img/trash.png" style="width: 14px; height: 14px; cursor: pointer; ">
                          </CButton>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  v-if="loadingButton == false"
                  class="text-center"
                  width="100%"
                >
                  <button
                    class="btn "
                    type="button"
                    disabled
                    style="color: darkcyan;"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    {{ $t('loading') }}
                  </button>
                </div>
              </CCol>
            </CRow>
            <CRow v-else>
              <CCol>
                <CJumbotron color="light">
                  <h4 class="text-center text-muted">
                    {{ $t('noItem')}}
                  </h4>
                </CJumbotron>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CRow>
        <CModal :show.sync="addPLUModal" size="lg" :title="titlename" centered>
          <CRow>
            <CCol md="3" sm="4">
              <div v-if="imageData.length > 0">
                <img
                  :src="imageData"
                  :ref="imageData"
                  @click="selectcolormodal"
                  alt="..."
                  width="100%"
                  class="card-img avatar"
                  @error="noImgUrl"
                />
              </div>
              <div v-else>
                <CButton
                  :style="{ backgroundColor: colori }"
                  @click="selectcolormodal"
                  style="width: 100%; height: 136px"
                >
                  <CIcon>
                    <i class="fas fa-square"></i>
                  </CIcon>
                </CButton>
              </div>
            </CCol>
            <CCol md="9" sm="8" class="form-group">
              <CRow>
                <CCol md="12">
                  <span>{{ $t('productName') }}</span>
                  <CInput
                    :disabled="isEcommercePage"
                    v-model="SKUName"
                    :placeholder="$t('productName')"
                    valid-feedback="input name!"
                  />
                </CCol>
                <CCol md="6" sm="6">
                  <span>{{ $t('price') }}</span>
                  <CInput
                    v-model.number="SKUPrice"
                    min="0"
                    type="number"
                    :placeholder="$t('price')"
                    valid-feedback="กรอกราคาสินค้า."
                  />
                </CCol>
                <CCol md="6" sm="6">
                  <span>{{ $t('pluCode') }}</span>
                  <CInput v-model="productcode" :disabled="isEcommercePage" :placeholder="$t('pluCode')" />
                </CCol>
                <CCol md="6" sm="6" class="">
                  <span>{{ $t('unit') }}</span>
                  <select :disabled="isEcommercePage" class="custom-select" v-model="unit">
                    <option value selected>{{ $t('unit') }}</option>
                    <option
                      v-for="unit in unitlist"
                      :key="unit.objectId"
                      :value="unit"
                    >
                      {{ unit.name }}
                    </option>
                  </select>
                </CCol>
                <CCol md="6" sm="6" class="">
                  <span>{{ $t('SKURatio') }}</span>
                  <CInput
                    v-if="indexdata == 0"
                    v-model.number="SKURatio"
                    :placeholder="$t('SKURatio')"
                    min="0"
                    disabled
                    :disabled="isEcommercePage"
                  />
                  <CInput
                    v-else
                    v-model.number="SKURatio"
                    type="number"
                    :placeholder="$t('SKURatio')"
                    min="1"
                    :disabled="isEcommercePage"
                  />
                </CCol>
                <CCol md="6" sm="6" v-show="isEcommercePage">
                  <span>{{ $t('inventoryQuantity') }}</span>
                  <CInput
                    v-model.number="inventoryQuantity"
                    min="0"
                    type="number"
                    :placeholder="$t('inventoryQuantity')"
                  />
                </CCol>
                <CCol md="6" sm="6" class="custom-control custom-checkbox mt-3">
                  <input
                    type="checkbox"
                    class="custom-control-input success"
                    v-model="mixedPrice"
                    id="mixedPrice"
                  />
                  <label class="custom-control-label" for="mixedPrice">
                    {{ $t('useStepPrice') }}
                  </label>
                </CCol>
                <CCol sm="6" col="6" class="form-group mt-3" v-if="isMenuEnabled">
                  <div class="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input success cursor"
                      id="isMenu"
                      v-model="isMenu"
                    />
                    <label class="custom-control-label cursor" for="isMenu">
                      {{ $t('isMenu') }}</label
                    >
                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <template #footer>
            <CRow class="justify-content-center col-md-12">
              <CCol col="5" lg="4">
                <CButton
                  block
                  color="success"
                  v-if="loadingSaveimg === true"
                  v-on:click="valtdateaddPLU"
                >
                  {{ $t('save') }}
                </CButton>
                <CButton
                  block
                  color="success"
                  v-else-if="loadingSaveimg === false"
                  disabled
                >
                  <CSpinner color="white" size="sm" /> {{ $t('save') }}
                </CButton>
              </CCol>
              <CCol col="1" lg="2"></CCol>
              <CCol col="5" lg="4">
                <CButton block color="light" @click="cancelUpdate()">
                  {{ $t('cancel') }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
      </CRow>
      <CRow>
        <CModal
          color="success"
          :show.sync="selectcolor"
          :title="$t('selectImg')"
          centered
        >
          <div v-if="loadingSaveimg">
            <CRow>
              <CCol
                md="1"
                sm="1"
                col="2"
                style="margin: 3px"
                v-for="item in colorList"
                :key="item.value"
                :value="item.value"
              >
                <CButton
                  v-if="indexColor === item.value"
                  style="box-shadow: 0 0 5px 5px rgb(163, 222, 194); color: white"
                  :class="{ active: indexColor === item.value }"
                  @click="setindexcolor(item.value)"
                  :style="{ backgroundColor: item.color }"
                >
                <i class="fi fi-rr-check"></i>
                </CButton>
                <CButton
                  v-else
                  :style="{ backgroundColor: item.color }"
                  :class="{ active: indexColor === item.value }"
                  @click="setindexcolor(item.value)"
                >
                  <CIcon>
                    <i class="fas fa-square fa-2x"></i>
                  </CIcon>
                </CButton>
              </CCol>
            </CRow>
            <hr />
            <div class="row">
              <div v-if="imageDataBtn.length > 0" class="col-md-6 col-6">
                <CButton @click="pickFile">
                  <label>{{ $t('selectImg') }}</label>
                  <input
                    type="file"
                    ref="image"
                    style="display: none"
                    accept="image/*"
                    @change="uploadImage"
                  />
                </CButton>
              </div>
              <div v-else class="col-md-6 col-6">
                <CButton @click="pickFile">
                  <label>{{ $t('selectImgFromPC') }}</label>
                  <input
                    type="file"
                    ref="image"
                    style="display: none"
                    accept="image/*"
                    @change="uploadImage"
                  />
                </CButton>
              </div>
              <div
                v-if="imageDataBtn.length > 0"
                class="col-md-6 col-6 text-right"
              >
                <CButton @click="removeImage">{{ $t('deleteImg') }}</CButton>
              </div>
              <div v-if="imageDataBtn.length > 0" class="col-md-12 text-center">
                <img
                  v-if="isUpload"
                  :src="imageData"
                  alt="..."
                  width="40%"
                  @click="selectcolormodal"
                  class="avatar"
                  @error="noImgUrl"
                />
                <VueCropper
                  v-else
                  v-show="imageDataBtn"
                  :guides="true"
                  :aspectRatio="4 / 4"
                  :initialAspectRatio="4 / 1"
                  ref="cropper"
                  :src="imageDataBtn"
                  alt="Source Image"
                >
                </VueCropper>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="text-center" width="100%">
              <button
                class="btn"
                type="button"
                disabled
                style="color: darkcyan"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                >
                </span>
                {{ $t('กำลังอัปโหลดรูปภาพ...') }}
              </button>
            </div>
          </div>
          <template #footer>
            <CRow class="justify-content-center col-md-12">
              <CCol col="5" lg="4">
                <CButton
                  block
                  color="success"
                  v-if="loadingSaveimg === true"
                  v-on:click="savecolorimg"
                >
                  {{ $t('save') }}
                </CButton>
                <CButton
                  block
                  color="success"
                  v-else-if="loadingSaveimg === false"
                  disabled
                >
                  <CSpinner color="white" size="sm" /> {{ $t('save') }}
                </CButton>
              </CCol>
              <CCol col="1" lg="2"></CCol>
              <CCol col="5" lg="4">
                <CButton block color="light" @click="selectcolor = false">
                  {{ $t('cancel') }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
      </CRow>
      <CRow>
        <CModal color="success" :show.sync="confirmModal" centered>
          <h4 class="text-center">{{ $t('confirmSaveImgDialog') }}</h4>
          <br />
          <h5 class="text-center">{{ $t('confirmSaveImg') }}</h5>
          <template #footer>
            <CRow class="justify-content-center col-md-12">
              <CCol v-show="!isEcommercePage" col="5" lg="4" v-if="loadingSaveimg === true">
                <CButton
                  block
                  v-if="showbuttonsave"
                  color="success"
                  v-on:click="saveProductPLU"
                >
                  {{ $t('save') }}
                </CButton>
                <CButton
                  block
                  color="success"
                  v-else
                  v-on:click="EditProductPLU"
                >
                  {{ $t('save') }}
                </CButton>
              </CCol>
              <CCol v-show="isEcommercePage" col="5" lg="4" v-if="loadingSaveimg === true">
                <CButton
                  block
                  color="success"
                  v-on:click="saveEcommerceInfo"
                >
                  {{ $t('save') }}
                </CButton>
              </CCol>
              <CCol col="5" lg="4" v-else-if="loadingSaveimg === false">
                <CButton block color="success" disabled>
                  <CSpinner color="white" size="sm" /> {{ $t('save') }}
                </CButton>
              </CCol>
              <CCol col="1" lg="2"></CCol>
              <CCol col="5" lg="4">
                <CButton block color="light" @click="confirmModal = false">
                  {{ $t('cancel') }}
                </CButton>
              </CCol>
            </CRow>
          </template>
        </CModal>
        <CRow>
          <modal
            color="danger"
            :show.sync="deleteModal"
            :title="$t('confirmDeleteProduct')"
            v-on:isSave="deletePlu"
            centered
            :loading="loading"
          >
            <br />
            <h4 class="text-center">{{ $t('comfrimDeletePLU') }}</h4>
            <br />
          </modal>
        </CRow>
        <CModal :show.sync="errorModel" centered color="danger">
          <div>
            <div class="d-flex flex-column align-items-center">
              <h4 class="mt-3">{{ $t('systemError') }}</h4>
            </div>
          </div>
          <template #footer>
          <div class="text-center w-100"><CButton color="danger" class="w-50" @click="errorModel = false">{{ $t('submit') }}</CButton>
          </div>
          </template>
        </CModal>
      </CRow>
      <Logout
        :isVisible="loginAgainModal"
        @update:isVisible="loginAgainModal = $event"
      />
    </form>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import Logout from '../../../containers/Logout.vue'
import util from '@/util/util'
import { mapGetters } from 'vuex'
import local from '@/services/local'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import permis from '@/util/permission'
import uploadImage from '@/util/uploadImage'
import mime from '@/util/mime'
import ecommerce  from "@/services/ecommerce"
import firebase from 'firebase'

export default {
  components: {
    Logout,
    VueCropper,
  },
  data() {
    const isEcommercePage = this.$route.path === '/ecommerce/ProductList/plu/' +this.$route.params.productSKUObjectId;
    return {
      isEcommercePage: isEcommercePage,
      inventoryQuantity: 0,
      errorModel: false,
      isSuccess: false,
      SKUName: '',
      data: [],
      indexColor: 1,
      dataUnit: [],
      itemObjectId: [],
      PLUlist: [],
      validateShop: false,
      shop: this.$store.getters.shopObjectId,
      addPLUModal: false,
      imageData: '',
      colori: '',
      indexdata: '',
      unit: [],
      SKURatio: 1,
      SKUPrice: 0,
      productcode: '',
      selectcolor: false,
      imageDataBtn: '',
      SKU: [],
      mixedPrice: false,
      remoteImagePath: '',
      confirmModal: false,
      loadingSaveimg: true,
      titlename: '',
      showbuttonsave: false,
      itemid: '',
      genObjectId: '',
      loadingButton: true,
      countPLU: 0,
      itemimageData: '',
      itemimageDataBtn: '',
      itemremoteImagePath: '',
      isUpload: true,
      isload: false,
      deleteModal: false,
      loading: true,
      textError: '',
      textMessage: '',
      alertError: false,
      isError: 0,
      isMenu: false,
      imgTmpPath: '',
      shopeeData:[],
      itemIdShopee: '',
      modelId: '',
      loginAgainModal : false,
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    isPermission() {
      let path = '/product'
      return permis.findPermissionRead('product', path)
    },
    isEditData() {
      let path = '/product'
      const value = permis.findPermissionEdit('product', path)
      return value// && this.editItem
    },
    editItem() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      if (shop == null) {
        return false
      }
      return shop.editableItem || false
    },
    isDeleteData() {
      let path = '/product'
      return permis.findPermissionRemove('product', path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.uid
    },
    colorList() {
      let data = []
      for (let i = 0; i < 30; i++) {
        data.push({
          color: util.generateColor(i),
          value: i,
        })
      }
      return data
    },
    isMenuEnabled() {
      if (this.shop) {
        if (this.shop.isMenuEnabled !== undefined) {
          return this.shop.isMenuEnabled
        } else {
          return false
        }
      }
    },
    items() {
      const data = this.PLUlist || []
      let detail = []

      for (let i = 0; i < data.length; i++) {
        const item = data[i]

        const remoteImagePath = item.remoteImagePath || ''
        const name = item.name || ''
        const unit = item.unit || {}
        const isMenu = item.isMenu || false
        const mixedPrice = item.mixedPrice || false
        const isProductPLU1 = item.isProductPLU1 || false

        const productPRU = item.ProductPRU || {}
        const price = util.convertCurrency(productPRU.exceedUc || 0)

        if (isProductPLU1 === false) {
          detail.push({
            remoteImagePath: remoteImagePath,
            name: name,
            unit: unit,
            SKURatio: item.SKURatio,
            PLUCode: item.PLUCode,
            price: price,
            colori: util.generateColor(item.indexColor),
            objectId: item.objectId,
            id: item.id,
            mixedPrice: mixedPrice,
            isMenu: isMenu,
            itemId: item.shopee?.item_id || '-',
            modelId: item.shopee?.model_id || '-'
          })
        }

        this.countPLU = detail.length
      }
      return detail
    },
    unitadd() {
      let data = this.dataUnit
      let detail = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].enabled === true) {
          if (data[i].name == 'ชิ้น') {
            detail = {
              id: data[i].id,
              name: data[i].name,
              objectId: data[i].objectId,
            }
          }
        }
      }
      return detail
    },
    unitlist() {
      let data = this.dataUnit
      let detail = []
      for (let i = 0; i < data.length; i++) {
        if (data[i].enabled === true) {
          detail.push({
            id: data[i].id,
            name: data[i].name,
            objectId: data[i].objectId,
          })
        }
      }
      return detail.sort(util.compare)
    },
  },
  created() {
    this.getProduct()
    this.getPLUlist()
    this.getUnit()
    this.shop = this.shops.find((i) => i.objectId === this.shop)
  },
  mounted() {
    document.addEventListener('keydown', this.doSave)
  },
  methods: {
    logout() {
      this.loadingButton = false
      firebase
        .auth()
        .signOut()
        .then(() => {
          // Sign-out successful.
          localStorage.clear()
          window.location.href = '/login'
        })
        .catch((error) => {
          console.log(error)
        })
    },
    productModel() {
        const uid = `${localStorage.getItem("shopsUid")}`;
        const shopObjectId = this.shopObjectId;
        const params = {
          shopObjectId: shopObjectId,
          itemId: this.itemIdShopee,
        };
        ecommerce({
          url: "/api/v1.0/shopee/product/models",
          params: params,
          method: "GET",
        })
          .then((response) => {
            const models = response.data.data.model
            const matchedModel = models.find(model => model.model_id === this.modelId)
            if (matchedModel) {
              this.inventoryQuantity = matchedModel.stock_info_v2.summary_info.total_available_stock
            } else {
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    saveEcommerceInfo(){
      this.updatePrice()
      this.updateStockProduct()
    },
    updatePrice() {
        const uid = `${localStorage.getItem("shopsUid")}`
        const shopObjectId = this.shopObjectId
        
        const params = {
          shopObjectId: shopObjectId,
        }
        const data = {
          itemId: this.itemIdShopee,
          modelId: this.modelId,
          price: Number(this.SKUPrice)
        }
        ecommerce({
          url: "/api/v1.0/shopee/product/price",
          params: params,
          data: data,
          method: "PATCH",
        })
        .then((response) => {
          if(response.data.error.code != 0){
            this.errorModel = true
          }else{
            this.confirmModal = false
            this.loadingSaveimg = true
            this.addPLUModal = false  
          }
        })
        .catch((error) => {
          console.log(error)
        })
      },
      updateStockProduct() {
        const uid = `${localStorage.getItem("shopsUid")}`;
        const shopObjectId = this.shopObjectId;
        const params = {
          shopObjectId: shopObjectId,
        }
        const data = {
          itemId: this.itemIdShopee,
          modelId: this.modelId,
          stock: Number(this.inventoryQuantity)
        }
        ecommerce({
          url: "/api/v1.0/shopee/product/stock",
          params: params,
          data: data,
          method: "PATCH",
        })
          .then((response) => {
            if(response.data.error.code != 0){
              this.errorModel = true
            }else{
              this.confirmModal = false
              this.loadingSaveimg = true
              this.addPLUModal = false  
            }
          })
          .catch((error) => {
            console.error('Error from check API', error);
          });
      },
    noImgUrl(event) {
      event.target.src = process.env.VUE_APP_NOIMAGE
    },
    cancelUpdate() {
      if (this.imgTmpPath != '') {
        this.removeImage()
      }
      this.addPLUModal = false
    },
    doSave(e) {
      if (this.$router.app._route.name == 'PLUType') {
        if (e.key === 'Enter' && e.target.nodeName === 'INPUT') {
          // button:not([disabled]),
          var focusableElementsString =
            'a[href], area[href], input:not([disabled]),select ,select:not([disabled]), textarea:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]'
          let arr = Array.from(
            e.target.form.querySelectorAll(focusableElementsString)
          )

          for (let i = 0; i < arr.length; i++) {
            if (arr[i] === e.target) {
              let indexOf = i < arr.length - 1 ? arr[i + 1] : indexOf[0]
              indexOf.focus()
              break
            }
          }
          e.preventDefault()
        }
      }
    },
    valtdateaddPLU() {
      if (this.productcode == '') {
        this.productcode = util.generateBarcode()
      }
      if (this.SKURatio < 0) {
        alert(this.$i18n.t('enterSKURatio') + ' !!!')
      }
      if (this.SKUPrice < 0) {
        alert(this.$i18n.t('enterPrice') + ' !!!')
      }
      if (this.SKUName == '') {
        alert(this.$i18n.t('enterProduct') + ' !!!')
      }
      if (this.unit == '') {
        alert(this.$i18n.t('selectUnit') + ' !!!')
      }
      if (
        this.productcode != '' &&
        this.SKURatio >= 0 &&
        this.SKUPrice >= 0 &&
        this.SKUName != '' &&
        this.unit != ''
      ) {
        this.alertError = false
        this.loading = true
        this.confirmModal = true
      }
    },
    openAddPluModal() {
      this.showbuttonsave = true
      this.indexdata = 1
      this.colori = 1
      this.SKUPrice = 0
      this.productcode = ''
      this.SKURatio = 1
      this.unit = this.unitadd
      this.mixedPrice = false
      this.isMenu = false
      this.imageData = this.itemimageDataBtn
      this.imageDataBtn = this.itemimageDataBtn
      this.remoteImagePath = this.itemimageDataBtn
      this.titlename = this.$i18n.t('addProductPLU')
      this.addPLUModal = true
    },
    openEditPluModal(item, index) {
      this.indexdata = index
      this.showbuttonsave = false
      this.itemObjectId = item.objectId
      this.itemid = item.id
      this.colori = item.colori
      this.SKUName = item.name
      this.SKUPrice = util.currencyStringToNumber(item.price)
      this.productcode = item.PLUCode
      this.unit = item.unit
      this.SKURatio = item.SKURatio
      this.isMenu = item.isMenu
      this.mixedPrice = item.mixedPrice
      this.itemIdShopee = item.itemId
      this.modelId = item.modelId
      this.productModel();
      if (item.remoteImagePath != undefined) {
        this.imageData = item.remoteImagePath
        this.imageDataBtn = item.remoteImagePath
        this.remoteImagePath = item.remoteImagePath
      }
      this.titlename = this.$i18n.t('editProductPLU')
      this.addPLUModal = true
    },
    deletePLU(item) {
      this.loading = true
      this.itemDelete = item
      this.deleteModal = true
    },
    deletePlu() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const plan = this.users.currentPlan
      const shopObjectId = this.shopObjectId
      let data = {
        shopObjectId: shopObjectId,
        objectId: this.itemDelete.objectId,
      }
      const headers = { shopObjectId: shopObjectId }

      this.loading = false
      local({
        method: 'post',
        url: '/api/v1.0/' + uid + '/ProductPLU/softdelete',
        params: { plan: plan },
        headers: headers,
        data: data,
      })
        .then(
          setTimeout(() => {
            this.loading = true
            this.deleteModal = false
            this.getPLUlist()
          }, 1000)
        )
        .catch((error) => {
          console.log(error)
        })
    },
    getPLUlist() {
      const uid = `${localStorage.getItem('shopsUid')}`
      let params = {
        skuObjectId: this.$route.params.productSKUObjectId,
        includePLU1: true,
      }
      const headers = { shopObjectId: this.shopObjectId }

      local({
        url: '/api/v1.0/' + uid + '/productplu/getbysku',
        params: params,
        headers: headers,
        method: 'GET',
      }).then((res) => {
        this.PLUlist = res.data.data
      })
    },
    selectcolormodal() {
      this.selectcolor = true
    },
    setindexcolor(index) {
      this.indexColor = index
      this.colori = util.generateColor(index)
    },
    generateObjectId() {
      const uid = `${localStorage.getItem('shopsUid')}`
      local({
        method: 'get',
        url: '/api/v1.0/' + uid + '/objectId/generate',
      })
        .then((res) => {
          this.genObjectId = res.data.data.objectId
        })
        .catch((error) => {
          console.log(error)
        })
    },
    pickFile() {
      this.$refs.image.click()
    },
    savecolorimg() {
      this.loadingSaveimg = false
      if (this.isload) {
        let cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
        this.imageData = cropedImage
        this.getImageURL()
      } else {
        this.selectcolor = false
        this.loadingSaveimg = true
      }
    },
    async getImageURL() {
      const uid = `${localStorage.getItem('shopsUid')}`

      let ref = this.genObjectId
      let image = this.imageData

      let ext = mime.getExtension(image)

      if (ext == null) {
        alert('Only Support File Type Is JPEG, PNG')
      } else {
        this.remoteImagePath = await uploadImage.onUpload(uid, ref, image, ext)
        if(!this.remoteImagePath.startsWith('https://firebasestorage')){
          this.loginAgainModal = true
        } else {
          this.loginAgainModal = false
        }
        this.imgTmpPath = this.remoteImagePath
        this.selectcolor = false
        this.loadingSaveimg = true
      }
    },
    uploadImage(e) {
      const file = e.target.files[0]
      // const image = e.target.files[0]
      // var input = e.target
      // const reader = new FileReader()
      // reader.readAsDataURL(image)
      // if (input.files && input.files[0]) {
      //   reader.onload = (e) => {
      //     this.imageDataBtn = e.target.result
      //     this.$refs.cropper = this.imageDataBtn
      //   }
      // }
      if (typeof FileReader === 'function') {
        this.dialog = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.imageDataBtn = event.target.result
          this.$refs.cropper = this.imageDataBtn
          this.isUpload = false
          this.isload = true
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
      if (this.genObjectId == '') {
        this.generateObjectId()
      }
    },
    async removeImage() {
      if (this.remoteImagePath != '') {
        await uploadImage.deleteImage(
          this.uid,
          this.shopObjectId,
          this.remoteImagePath
        )
      }
      this.imageData = ''
      this.imageDataBtn = ''
      this.remoteImagePath = ''
    },
    getUnit() {
      const uid = `${localStorage.getItem('shopsUid')}`
      let params = {
        page: 1,
        limit: 1000,
      }

      const shopObjectId = this.shopObjectId
      const headers = {
          shopObjectId: shopObjectId,
      }

      local
        .get('/api/v1.0/uid/' + uid + '/Unit/data', { params ,headers})
        .then((res) => {
          this.dataUnit = res.data.data
        })
    },
    saveProductPLU() {
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const plan = this.users.currentPlan
      this.loadingSaveimg = false
      let data = []
      if (this.genObjectId != '') {
        data = {
          objectId: this.genObjectId,
          PLUCode: this.productcode, //barcode
          SKURatio: this.SKURatio, //ตัดสต็อก
          enabled: true,
          indexColor: this.indexColor,
          mixedPrice: this.mixedPrice, //radio but
          name: this.SKUName, //เพิ่มเติม ถ้ามีแก้ไข
          productSKU: {
            SKUName: this.SKUName,
            SKUPrice: this.SKUPrice,
            id: this.SKU.id,
            objectId: this.SKU.objectId,
          },
          productSKUId: this.SKU.id,
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          unit: {
            //หน่วยใหม่
            id: this.unit.id,
            name: this.unit.name,
            objectId: this.unit.objectId,
          },
          unitId: this.unit.id,
          price: this.SKUPrice, //ราคา
          remoteImagePath: this.remoteImagePath,
        }
      } else {
        data = {
          PLUCode: this.productcode, //barcode
          SKURatio: this.SKURatio, //ตัดสต็อก
          enabled: true,
          indexColor: this.indexColor,
          mixedPrice: this.mixedPrice, //radio but
          name: this.SKUName, //เพิ่มเติม ถ้ามีแก้ไข
          productSKU: {
            SKUName: this.SKUName,
            SKUPrice: this.SKUPrice,
            id: this.SKU.id,
            objectId: this.SKU.objectId,
          },
          productSKUId: this.SKU.id,
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          unit: {
            //หน่วยใหม่
            id: this.unit.id,
            name: this.unit.name,
            objectId: this.unit.objectId,
          },
          unitId: this.unit.id,
          price: this.SKUPrice, //ราคา
        }
      }
      if (this.shop.isMenuEnabled) {
        Object.assign(data, { isMenu: this.isMenu })
      }
      this.loading = false
      local({
        method: 'post',
        url: '/api/v1.0/' + uid + '/productplu/add',
        params: { shopObjectId: shopObjectId, plan: plan },
        data: data,
      })
        .then(
          setTimeout(() => {
            this.confirmModal = false
            this.loadingSaveimg = true
            this.addPLUModal = false
            this.getPLUlist()
            // this.getProduct()
          }, 1000)
        )
        .catch((error) => {
          console.log(error)
        })
    },
    EditProductPLU() {
      this.loadingSaveimg = false
      this.shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      const plan = this.users.currentPlan
      let data = []
      if (this.remoteImagePath != '') {
        data = {
          id: this.itemid,
          objectId: this.itemObjectId,
          PLUCode: this.productcode, //barcode
          SKURatio: this.SKURatio, //ตัดสต็อก
          enabled: true,
          indexColor: this.indexColor,
          mixedPrice: this.mixedPrice, //radio but
          name: this.SKUName, //เพิ่มเติม ถ้ามีแก้ไข
          productSKU: {
            SKUName: this.SKUName,
            SKUPrice: this.SKUPrice,
            id: this.SKU.id,
            objectId: this.SKU.objectId,
          },
          productSKUId: this.SKU.id,
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          unit: {
            //หน่วยใหม่
            id: this.unit.id,
            name: this.unit.name,
            objectId: this.unit.objectId,
          },
          unitId: this.unit.id,
          price: this.SKUPrice, //ราคา
          remoteImagePath: this.remoteImagePath,
        }
      } else {
        data = {
          id: this.itemid,
          objectId: this.itemObjectId,
          PLUCode: this.productcode, //barcode
          SKURatio: this.SKURatio, //ตัดสต็อก
          enabled: true,
          indexColor: this.indexColor,
          mixedPrice: this.mixedPrice, //radio but
          name: this.SKUName, //เพิ่มเติม ถ้ามีแก้ไข
          productSKU: {
            SKUName: this.SKUName,
            SKUPrice: this.SKUPrice,
            id: this.SKU.id,
            objectId: this.SKU.objectId,
          },
          loading: true,
          productSKUId: this.SKU.id,
          shop: {
            objectId: this.shop.objectId,
            id: this.shop.id,
            branchName: this.shop.branchName,
          },
          shopId: this.shop.id,
          unit: {
            //หน่วยใหม่
            id: this.unit.id,
            name: this.unit.name,
            objectId: this.unit.objectId,
          },
          unitId: this.unit.id,
          price: this.SKUPrice, //ราคา
        }
      }
      if (this.shop.isMenuEnabled) {
        Object.assign(data, { isMenu: this.isMenu })
      }
      local({
        method: 'post',
        url: '/api/v1.0/' + uid + '/productplu/add',
        params: { shopObjectId: shopObjectId, plan: plan },
        data: data,
      })
        .then(
          setTimeout(() => {
            this.confirmModal = false
            this.loadingSaveimg = true
            this.addPLUModal = false
            this.getPLUlist()
            this.getProduct()
          }, 1000)
        )
        .catch((error) => {
          console.log(error)
        })
    },
    getProduct() {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      let params = {
        shopObjectId: shopObjectId,
        categoryObjectId: '',
      }

      local
        .get(
          '/api/v1.0/' +
            uid +
            '/ProductSKU/data/' +
            this.$route.params.productSKUObjectId,
          { params }
        )
        .then((res) => {
          let data = res.data.data.document
          this.SKU = data
          if (data.SKUName !== undefined) {
            this.SKUName = data.SKUName
          }

          this.colori = util.generateColor(data.indexColor)
          if (data.remoteImagePath != undefined) {
            this.itemimageData = data.remoteImagePath
            this.itemimageDataBtn = data.remoteImagePath
          }
          this.loadingButton = true
        })
    },
    goback() {
      let productSKUObjectId = this.$route.params.productSKUObjectId;

      if (this.$route.path.includes('/product/productdetail/plu/')) {
        this.$router.push({
          name: 'ProductDetail',
          params: { productSKUObjectId },
          query: {
            sort: this.$route.query.sort,
            page: this.$route.query.page,
            view: this.$route.query.view,
          },
        });
      } else if (this.$route.path.includes('/ecommerce/ProductList/plu/')) {
        this.$router.push({
          name: 'EcommerceProductDetail',
          params: { productSKUObjectId },
          query: {
            sort: this.$route.query.sort,
            page: this.$route.query.page,
            view: this.$route.query.view,
          },
        });
      }
    },

  },
}
</script>

<style>
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #3abc98;
  background-color: #3abc98;
}
.cursor {
  cursor: pointer;
}
</style>
