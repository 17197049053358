import { render, staticRenderFns } from "./PLUType.vue?vue&type=template&id=9f2624fc&"
import script from "./PLUType.vue?vue&type=script&lang=js&"
export * from "./PLUType.vue?vue&type=script&lang=js&"
import style0 from "./PLUType.vue?vue&type=style&index=0&id=9f2624fc&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports